import { useState } from "react";
import Snowfall from "react-snowfall";
import OpenAI from "openai";
import Loader from "./Loader";
import logo from "./snowflake.png";
import Button from "./Button.js";

import "./App.css";

const snowflake1 = document.createElement("img");
snowflake1.src = logo;

const images = [snowflake1];

const cuteScenes = [
  "A Christmas scene with a puppy playing in the snow, surrounded by festive decorations.",
  "A Christmas scene with a puppy playing in the snow, surrounded by festive decorations.",
  "An adorable kitten wearing a tiny Santa hat, sitting beside a cozy fireplace with Christmas stockings.",
  "A bunny wearing a Christmas sweater, surrounded by presents and holiday treats.",
  "A koala in a Christmas scarf, nestled in a eucalyptus tree adorned with ornaments.",
  "Ducklings waddling through the snow with tiny Santa hats, following a trail of holiday breadcrumbs.",
  "A sloth hanging from a tree branch decorated with ornaments, with a small present below.",
  "A Christmas-themed scene with a puppy wearing a tiny Santa hat, surrounded by colorful presents and snowflakes.",
  "An adorable kitten nestled in a cozy Christmas stocking, surrounded by twinkling lights and ornaments.",
  "A bunny wearing a festive scarf, sitting beside a miniature Christmas tree adorned with tiny rabbit-sized ornaments.",
  "A koala sitting in a eucalyptus tree decorated with tinsel and baubles, enjoying a Christmas feast of eucalyptus leaves.",
  "A group of ducklings waddling through the snow, each wearing a cute Santa hat, with a snowman in the background.",
  "A sloth hanging from a Christmas tree branch, surrounded by dangling ornaments and wrapped gifts below.",
  "A heartwarming image of a group of puppies wearing festive sweaters, gathered around a Christmas tree with presents.",
  "Create a cute scene with a West Highland Terrier puppy playing in the snow next to a snowman with a Santa hat.",
  "Illustrate a cozy winter setting with kittens curled up on a holiday-themed blanket, surrounded by twinkling lights and ornaments.",
  "Generate an adorable picture of a bunny wearing a tiny Santa outfit, nibbling on a candy cane in a snowy meadow.",
  "Design a cute holiday image with koalas hanging onto eucalyptus branches adorned with Christmas lights and ornaments.",
  "Generate a heartwarming picture of ducklings wearing tiny elf hats, waddling through the snow with a pond frozen for ice-skating.",
  "Create a charming scene with Highland cows wearing Christmas wreaths, grazing in a snow-covered Scottish landscape.",
  "Illustrate a Christmassy setting with West Highland Terrier puppies playing with holiday decorations in a cozy living room.",
  "Craft a cute image of kittens exploring a pile of gift-wrapped presents under a twinkling Christmas tree.",
  "Generate an adorable picture of bunnies wearing festive scarves, gathered around a plate of Christmas cookies.",
  "Create a heartwarming image of koalas exchanging presents in a snowy eucalyptus grove.",
  "Illustrate a cute holiday picture with ducklings riding on a sled down a snowy hill, led by a duck dressed as Santa.",
  "Craft a charming scene with Highland cows wearing Santa hats and enjoying a snowy day in the Highlands.",
  "Generate an adorable image of West Highland Terrier puppies building a snowdog in their backyard.",
  "Create a festive scene with kittens wearing Christmas-themed bow ties, surrounded by glowing candles and ornaments.",
  "Design a cute picture of bunnies enjoying a snowy day, with one bunny pulling a tiny sleigh with gifts.",
  "Craft a heartwarming image of koalas roasting marshmallows over a winter campfire in the eucalyptus forest.",
  "An adorable Christmas scene featuring playful puppies wearing Santa hats, surrounded by festive decorations and presents.",
  "Create a festive image with a West Highland Terrier in a Christmas sweater, sitting by the fireplace with stockings and a tree.",
  "Design a heartwarming picture of kittens playing with tinsel and ornaments in a cozy living room adorned with Christmas lights.",
  "Generate a cute holiday scene with a bunny wearing a festive bow, exploring a snowy garden with twinkling snowflakes.",
  "Illustrate a Christmas setting with koalas in Santa hats, munching on eucalyptus leaves in a snow-covered eucalyptus forest.",
  "Create a delightful scene with ducklings in Christmas scarves, waddling through a snowy landscape with a pond and ice-skates.",
  "Design a charming image of highland cows adorned with holiday bells, grazing in a snowy Highland meadow.",
  "Generate a festive picture with West Highland Terriers wearing reindeer antlers, surrounded by Christmas presents and ornaments.",
  "Craft a cozy scene of kittens curled up by the fireplace, with Christmas stockings hanging and a glowing Christmas tree.",
  "Create a Christmas morning image of bunnies exchanging gifts under a tree adorned with bunny-shaped ornaments.",
  "Generate a heartwarming picture of koalas in Christmas sweaters, napping on festive blankets under a twinkling starry sky.",
  "Craft a cute holiday scene with ducklings singing Christmas carols in a winter pond surrounded by snowy reeds.",
  "Design an adorable image of highland cows wearing Santa hats, sharing a festive meal of hay in a snowy Highland pasture.",
  "Create a Christmas morning picture of West Highland Terriers opening presents, with wrapping paper scattered around.",
  "Illustrate a festive scene of kittens climbing a Christmas tree adorned with catnip-filled ornaments.",
  "Generate a merry image of bunny carolers singing in a snowy woodland with twinkling fireflies.",
  "Design an adorable picture of koala siblings decorating a eucalyptus tree with festive ornaments in a snowy landscape.",
  "Create an enchanting Christmas scene with playful puppies wearing Santa hats, surrounded by twinkling lights and gift-wrapped bones.",
  "Design a festive image featuring a West Highland Terrier in a cozy Christmas sweater, sitting by the fireplace with stockings and a decorated tree.",
  "Generate a heartwarming picture of kittens playing with tinsel and ornaments in a room filled with the warm glow of Christmas lights.",
  "Craft a delightful holiday scene with bunny rabbits wearing festive scarves, exploring a snowy garden adorned with twinkling snowflakes.",
  "Illustrate a merry Christmas setting with koalas donning Santa hats, nibbling on eucalyptus leaves in a snow-covered eucalyptus forest.",
  "Create an adorable scene with ducklings wearing tiny Santa hats, waddling through a winter wonderland with a pond and ice-skates.",
  "Design a charming image of highland cows adorned with holiday bells, grazing in a snowy Highland meadow with Christmas wreaths.",
  "Generate a festive picture with West Highland Terriers wearing reindeer antlers, surrounded by Christmas presents and ornaments.",
  "Craft a cozy holiday scene of kittens curled up by the fireplace, with Christmas stockings hanging and a glowing Christmas tree.",
  "Create a heartwarming Christmas morning image of bunny rabbits exchanging gifts under a tree adorned with bunny-shaped ornaments.",
  "Illustrate a merry scene with koalas riding a sleigh through a snowy eucalyptus forest, pulled by festive kangaroos.",
  "Generate a cute picture of ducklings singing Christmas carols in a winter pond surrounded by snowy reeds.",
  "Craft a delightful Christmas scene with highland cows wearing Santa hats, sharing a festive meal of hay in a snowy Highland pasture.",
  "Design an enchanting image of West Highland Terriers opening Christmas presents, with wrapping paper scattered around.",
  "Create a festive scene of kittens climbing a Christmas tree adorned with catnip-filled ornaments.",
  "Illustrate a merry holiday image of bunny carolers singing festive songs in a snowy woodland with twinkling fireflies.",
  "Generate a heartwarming scene with koalas enjoying a hot cocoa party in a bamboo-decorated winter den.",
  "Craft an adorable picture of ducklings building a snowman in a winter wonderland.",
  "Design a festive Christmas scene with highland cows exchanging gifts in a snowy meadow with Christmas bells.",
  "Generate a cozy image of puppies in Christmas sweaters, snuggling by the fireplace with festive holiday blankets.",
];

function App() {
  const [result, setResult] = useState("");
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);

  const openai = new OpenAI({
    apiKey: process.env.REACT_APP_OPEN_AI_KEY,
    dangerouslyAllowBrowser: true,
  });

  const generateImage = async () => {
    setLoading(true);
    setLoaded(false);
    const prompt = cuteScenes[Math.floor(Math.random() * cuteScenes.length)];
    const image = await openai.images.generate({
      model: "dall-e-3",
      prompt: prompt,
      n: 1,
    });
    // console.log(image.data);
    setResult(image.data[0].url);
    setLoading(false);
  };

  return (
    <div className="app">
      <h1>Miss Burke's Christmas Cuteness Generator</h1>

      {result.length > 0 && !loading && (
        <div className="button-container">
          <Button
            text="Want more?"
            onClick={generateImage}
            style={{ marginBottom: "20px" }}
          />
        </div>
      )}

      <div className="gallery-image">
        {loading ? (
          <Loader />
        ) : result.length > 0 ? (
          <img
            src={result}
            onLoad={() => setLoaded(true)} // set loaded to true when the image loads
            className={loaded ? "" : "blur"}
          />
        ) : (
          <div className="button-container">
            <Button
              text="Fill With Christmas Cuteness"
              onClick={generateImage}
            />
          </div>
        )}
      </div>

      <Snowfall
        className="snowfall"
        snowflakeCount={80}
        speed={[0.5, 3]}
        radius={[0.5, 30]}
        wind={[-0.5, 3]}
        rotationSpeed={[-1, 1]}
        images={images}
        style={{ background: "rgb(0, 0, 0, 0)" }}
      />
    </div>
  );
}

export default App;
