import React from "react";
import { Triangle } from "react-loader-spinner";

const Loader = () => {
  return (
    <Triangle
      color="white"
      height={130}
      width={130}
      timeout={3000} //3 secs
    />
  );
};

export default Loader;
